<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
      <Summary
        :sadaqahUserDonatingType="sadaqahUserDonatingType"
        :user="user"
        :anonymous="anonymous"
        :paymentMethod="paymentMethod"
        :donatingAs="donatingAs"
        :paymentFrequencyDetails="paymentFrequencyDetails"
        :totalDailySadaqah="totalDailySadaqah"
        :bankAccountDetails="bankAccountDetails"
        @goToPayment="goToPayment"
        @goToDonor="goToDonor"
      />
      <div class="row mx-0 justify-content-center">
        <div class="col-10 my-4 text-center divider">
        </div>
      </div>
      <SetReference v-model:reference="referenceValue" />
      <AcceptTerms v-model:acceptTerms="acceptTermsValue" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation',
  props: ['reference', 'sadaqahUserDonatingType', 'acceptTerms', 'activeComponent', 'user', 'anonymous', 'paymentMethod', 'donatingAs', 'paymentFrequencyDetails', 'totalDailySadaqah', 'bankAccountDetails'],
  emits: ['update:reference', 'update:acceptTerms', 'update:activeComponent'],
  components: {
    Summary: defineAsyncComponent(() => import('./views/Summary.vue')),
    SetReference: defineAsyncComponent(() => import('./views/SetReference.vue')),
    AcceptTerms: defineAsyncComponent(() => import('./views/AcceptTerms.vue'))
  },
  computed: {
    referenceValue: {
      get () {
        return this.reference
      },
      set (val) {
        this.$emit('update:reference', val)
      }
    },
    acceptTermsValue: {
      get () {
        return this.acceptTerms
      },
      set (val) {
        this.$emit('update:acceptTerms', val)
      }
    },
    activeComponentValue: {
      get () {
        return this.activeComponent
      },
      set (val) {
        this.$emit('update:activeComponent', val)
      }
    }
  },
  methods: {
    goTo (val) {
      console.log('Review Donation goTo', val)
      this.$emit('goTo', val)
    },
    goToDonor () {
      if (this.user) {
        this.activeComponentValue = 'UserDonorDetails'
      } else {
        this.activeComponentValue = 'AnonymousDonorDetails'
      }
    },
    goToPayment () {
      this.activeComponentValue = 'SelectPaymentMethod'
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
